export default defineAppConfig({
  tairo: {
    sidebar: {
      toolbar: {
        enabled: true,
        showTitle: true,
        showNavBurger: true,
        tools: {
          left: [
            // {
            //   component: 'DatasetSelect'
            // },
            {
              component: 'SizeControl'
            },
            {
              component: 'ApiControl'
            },
            {
              component: 'DatasetControl'
            },
            {
              component: 'PlanLockControl'
            }
          ],
          right: [
            {
              component: 'ThemeToggle'
              // props: {
              //   disableTransitions: true,
              // },
            },
            {
              component: 'ToolbarDatetime'
            },
            {
              component: 'AuthControl'
            },
            {
              component: 'ToolbarHelp'
            }
          ]
        }
      },
      navigation: {
        enabled: true,
        startOpen: false,
        logo: {
          component: 'CezLogo',
          resolve: true,
          props: {
            class: 'text-primary-600 h-10'
          }
        },
        items: [
          {
            title: 'Přípravný modul',
            icon: { name: 'mdi-chart-timeline', class: 'w-6 h-6' },
            subsidebar: { component: 'MenuPreparation' }
          },
          {
            title: 'Plánovací modul',
            icon: { name: 'mdi-finance', class: 'w-6 h-6' },
            subsidebar: { component: 'MenuPlanning' },
            roles: []
          },
          {
            title: 'Hodnotící modul',
            icon: { name: 'mdi-database-check-outline', class: 'w-6 h-6' },
            subsidebar: { component: 'MenuAssessment' },
            roles: []
          },
          {
            title: 'Správa aplikace',
            icon: { name: 'mdi-cog-outline', class: 'w-6 h-6' },
            subsidebar: { component: 'MenuAdmin' },
            roles: ['admin']
            // position: 'end'
          },
          {
            title: 'Odhlásit se',
            icon: { name: 'mdi-logout', class: 'w-6 h-6' },
            to: '/auth/logout/',
            position: 'end',
            roles: []
          }
        ]
      }
    }
  }
})
