import { useApi } from '~/composables/api'

export interface Dataset {
  id?: number
  abbr: string
  name_cs: string
  name_en: string
}

interface State {
  detail: Dataset | undefined | null
  list: Dataset[]
  selected: Dataset | undefined | null
}

export const useDatasetStore = defineStore('datasets', {
  state: (): State => ({
    detail: null,
    list: [],
    selected: null
  }),
  actions: {
    async create() {
      return await useApi('/dataset', 'POST', { data: this.detail })
    },
    async delete(id: number | string) {
      await useApi(`/dataset/${id}`, 'DELETE')
    },
    async getDetail(id: number | string) {
      this.detail = (await useApi(`/dataset/${id}`, 'GET')) as Dataset
    },
    async getList() {
      this.list = (await useApi('/dataset?limit=9999&offset=0', 'GET')) as Dataset[]
    },
    async update(data: Dataset, id: number | string) {
      return await useApi(`/dataset/${id}`, 'PUT', { data })
    }
  }
})
