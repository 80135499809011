import { useDatasetStore } from '~/store/datasets'

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const datasetStore = useDatasetStore()
  await datasetStore.getList()

  const isvSelectedDatasetId = localStorage.getItem('isvSelectedDatasetId')

  if (!isvSelectedDatasetId) {
    datasetStore.selected = datasetStore.list[0]
    return
  }

  const selectedDataset = datasetStore.list.find((dataset) => dataset.id === Number(isvSelectedDatasetId))
  datasetStore.selected = selectedDataset || datasetStore.list[0]
})
